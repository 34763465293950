import React, { forwardRef, useEffect, useState } from 'react'
import "./contact-us.css"
import Button from '../../../../components/ui/Button'
import contactIcon  from './assets/images/contact.png'
import { API_URL } from '../../../../services/api'
import {getFetch} from  "../../../../libs/fetch.js"

const ContactUs = forwardRef(({prop}, ref) => {
    const [contactForm, setContactForm] = useState({
        name: "",
        email: "",
        message: ""
    })

    const handleFormData = (e) => {
        try{
            const {name, value} = e.target;
            setContactForm(oldData => {
                return {
                    ...oldData,
                    [name]: value
                }
            })
        }
        catch(error){
            // console.log(error)
        }
    }
    const allFieldValid = Object.keys(contactForm).every(key => contactForm[key] === -1)
    console.log(allFieldValid, "")

    const handleFormSubmit = async (e) => {
        e.preventDefault()

        const allFieldValid = Object.keys(contactForm).every(key => contactForm[key] !== "")
        try{
            if(!allFieldValid){
                throw new Error("Fill all details")
            }

            const payload =  {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({contact: contactForm})
            }
            const info = await getFetch(`${API_URL}/user/contact`, payload)

            setContactForm({
                name: "",
                email: "",
                message: ""
            })
        }
        catch(error){
            // alert(error.message)
        }
    }

  return (
    <section className="contact-section" ref={ref}>

        <div className="contact-wrapper">
            <div className="contact-intro">
                <h2 className="contact-head">Get in Touch with Us</h2>
                <p className="contact-text">
                    Whether you have inquiries or need support, our team is ready to assist you. Contact us today!
                </p>
            </div>

            <form className="contact-form" onSubmit={handleFormSubmit}>
                <input 
                    type="text" 
                    name="name" 
                    value={contactForm.name} 
                    placeholder="name"  
                    onChange={handleFormData}
                    className="landing-page-contact-input"
                    required
                />
                <input 
                    type="email" 
                    name="email" 
                    value={contactForm.email} 
                    placeholder="email"  
                    onChange={handleFormData}
                    className="landing-page-contact-input"
                    required
                />
                <textarea 
                    name="message" 
                    value={contactForm.message} 
                    placeholder="message"  
                    onChange={handleFormData}
                    className="landing-page-contact-input"
                    required
                />

                <Button 
                    text={
                        <>
                            Submit
                            <svg aria-hidden="true" width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 5C0 4.84127 0.0508906 4.7123 0.152672 4.6131C0.254453 4.51389 0.386768 4.46429 0.549618 4.46429H14.0458L10.4427 0.952381C10.3206 0.833333 10.2595 0.694445 10.2595 0.535714C10.2595 0.376984 10.3206 0.248015 10.4427 0.148809C10.5649 0.0496035 10.7074 0 10.8702 0C11.0331 0 11.1756 0.0396824 11.2977 0.119047L15.8779 4.58333C15.9593 4.70238 16 4.84127 16 5C16 5.15873 15.9593 5.29762 15.8779 5.41667L11.2977 9.88095C11.1756 9.96032 11.0331 10 10.8702 10C10.7074 10 10.5649 9.9504 10.4427 9.85119C10.3206 9.75198 10.2595 9.62302 10.2595 9.46429C10.2595 9.30556 10.3206 9.16667 10.4427 9.04762L14.0458 5.53571H0.549618C0.386768 5.53571 0.254453 5.48611 0.152672 5.3869C0.0508906 5.2877 0 5.15873 0 5Z" fill="white"/>
                            </svg>
                        </>
                    }
                    className="landing-page-contact-btn"
                 />
            </form>
        </div>
      
    </section>
  )
})

export default ContactUs
