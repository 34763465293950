import React from 'react'
import "./join-community.css"
import { NavLink } from 'react-router-dom'

const JoinCommunity = () => {
  return (
    <section className="community">

        <div className="community-container">
            <img 
                className="community"
                src="https://img.freepik.com/free-photo/people-stacking-hands-together-park_53876-63293.jpg?t=st=1721864948~exp=1721868548~hmac=5fdecaf4ee802bda453da15afc02423157c1a6081d904f395b6049cd14fc3a63&w=740"
                alt="People stacking hands together in the park"
            />
            <div class="overlay"></div>

            <h2 className="community">Join Our Community</h2>

            <p className="community">Join Ventmoir's supportive community to connect with individuals who understand your journey and offer mutual support.</p>

            <NavLink to="https://chat.whatsapp.com/BX7J6GVTxrhECuJOuqAxOT" target="_blank" className="community">
                Join our community
            </NavLink>
        </div>

    </section>
  )
}

export default JoinCommunity
