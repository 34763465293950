import React, { useEffect, useRef, useState } from 'react'
import "./hero.css"
import { NavLink, plant, supportFrame, useWindowWidth, diverseGroup } from '../../index'
import heroImg from "./assets/svgs/hr.svg"
import Button from '../../../../components/ui/Button'
import shapes from "./h.svg"
import rocket from "./rocket.svg"
import WaitListModal from '../waitListmodal/WaitlistModal'

const Hero = ({showWaitlist, setShowWaitlist, setShowMessage}) => {
  const {screenWidth} = useWindowWidth()
  const isMobile = screenWidth <=768
  // const text = "...bridging the gap between social media and mental health."
  const text = "...eradicating the isolation struggle often associated with mental health."

  const targetDate = new Date('August 25, 2024');

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;

    if (difference <= 0) {
      return null;
    }

    const timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTimeLeft = () => {
    if (!timeLeft) {
      return 'Countdown is over!';
    }

    return `${timeLeft.days}d : ${timeLeft.hours}h : ${timeLeft.minutes}m : ${timeLeft.seconds}s`;
  };

  return (
    <section className="hero-section">
      {showWaitlist &&
        <WaitListModal setShowWaitlist={setShowWaitlist} setShowMessage={setShowMessage} />}
      {!isMobile && <img
        className="shared-background"
        aria-hidden="true"
        src={shapes}
        style={{zIndex: "-1"}}
      />}
      {/* <aside className="plant-icon" aria-hidden="true">
        <img src={plant} className="plant" alt="plant" />
        <img src={plant} className="plant" alt="plant" />
      </aside>       */}

      <article className="hero-article">
        <header className="hero-content"> 

          <div className="launching-soon">
            <img className="launching-soon" src={rocket} alt="Launching soon" />
            {/* <p className="launching-soon">Launching on August 25th, 2024.
              <br /> 
              {formatTimeLeft()} left
            </p> */}
            <p className="launching-soon">Launching soon
            </p>
          </div>

          <h1 className="hero-head">
            Welcome to <span>Ventmoir,</span> Where Every Voice Is Valued and <span>Every Story Counts</span>
          </h1>
          
          <p className="hero-text">
            Connect with others who understand and find solace in the collective strength of our societies.
          </p>
         
          {/* <NavLink to={"/register"} className="join-us">Join us</NavLink> */}
          <div className="hero-link-container">
            <NavLink 
              className="waitlist"
              onClick={() => setShowWaitlist(true)}
            >Join waitlist</NavLink>
            <NavLink to="https://chat.whatsapp.com/BX7J6GVTxrhECuJOuqAxOT" target="_blank" className="waitlist">Join our community</NavLink>
          </div>
    
        </header>

        <section className="hero-image-container">
          <img src={diverseGroup} className="hero-img" alt="Diverse group participating in mental health support session." />
        </section>
      </article>

      <section className="hero-fade-in-container">
        <p className="hero-fade-in-text">
          {text.split("").map((char, index) => (
            <span key={index} style={{ "--index": index }}>
              {char === " " ? '\u00A0' : char}
            </span>
          ))}
        </p>
      </section>
      {/* <aside className="plant-icon" aria-hidden="true">
        <img src={plant} className="plant" alt="plant" />
        <img src={plant} className="plant" alt="plant" />
      </aside>   */}
    </section>
  )
}

export default Hero
