import React, { forwardRef } from 'react'
import "./how-we-help.css"
import SupportArticle from './components/SupportArticle'
import { shape, union, useWindowWidth } from '../..';
import ear from "./assets/svgs/ear.svg"
import safe from "./assets/svgs/safe.svg"
import listen from "./assets/svgs/listen.svg"
import societies from "./assets/svgs/societies.svg"

const HowWeHelp = forwardRef((props, ref) => {
  const {screenWidth} = useWindowWidth()
  const isMobile = screenWidth <= 767
  return (
    <section className="support-section" ref={ref}>
     
      <h2 className="support-title">How we help</h2>
      <article className="support-container">
        <div>
          <div className='intro'>  
            <h3 className="support-intro">Our Approach to Mental Wellness</h3>
            <p className="support-intro">Learn about our comprehensive services designed to support your mental wellness</p>
          </div>

          <ul className="support-list">
            <li className="support-list-item g">
              <img className="support-list-img" src={listen} alt="Anonymous support" />

              <h4 className="support-list-item-title">Anonymous Support</h4>
              <p className="support-list-item-text">Choose to remain anonymous while seeking and providing support. Your comfort and confidence are respected every step of the way.</p>
            </li>

            <li className="support-list-item bl">
              <img className="support-list-img" src={societies} alt="Supportive societies" />

              <h4 className="support-list-item-title">Supportive Societies</h4>
              <p className="support-list-item-text">Explore societies on various mental health topics and connect with others who share similar experiences.</p>
            </li>

            {isMobile && <li className="support-list-item">
              <img 
                className="support-list-item-img"
                src="https://img.freepik.com/free-photo/people-sharing-feelings-emotions-group-therapy-session_23-2151074031.jpg?t=st=1722902006~exp=1722905606~hmac=c25009e3aae68d83550c373fd5b80e088a24fcbe25960dc2f5720bc3a6950594&w=740" 
                // src="https://img.freepik.com/free-photo/medium-shot-people-hugging-meeting_23-2148686581.jpg?t=st=1721832905~exp=1721836505~hmac=55639f29ecf68a4ec444d7b9f6639d16640987bc2243c6a17feace3e4d2427d3&w=740" 
                alt="People sharing feelings and emotions during group therapy session" 
                style={{objectFit: "cover"}}
              />
            </li>}

            <li className="support-list-item bl">     
              <img className="support-list-img" src={safe} alt="Safe space" />

              <h4 className="support-list-item-title">Safe Space</h4>
              <p className="support-list-item-text">Our platform provides a safe and judgment-free space for you to express yourself. Feel free to share your thoughts and emotions without fear of criticism.</p>
            </li>

            <li className="support-list-item g">
              <img className="support-list-img" src={ear} alt="Compassionate listening" />

              <h4 className="support-list-item-title">Compassionate Listening</h4>
              <p className="support-list-item-text">Our platform provides a haven where empathetic listeners lend an ear to your story, fostering understanding and acceptance</p>
            </li>
          </ul>
        </div>

        {!isMobile &&
          <div className="support-img-container">
            <img 
              className="support-img"
              src="https://img.freepik.com/free-photo/people-sharing-feelings-emotions-group-therapy-session_23-2151073971.jpg?t=st=1722902317~exp=1722905917~hmac=d6765ecd8d0deeedea2076f539676c0185b9a9ee12f2f9c452ea2509b6313b03&w=740" 
              alt="People sharing feelings and emotions during group therapy session" 
            />
          </div>
        }
      </article>
    </section>
  )
})

export default HowWeHelp
