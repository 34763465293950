import { useEffect } from "react";
import LandingPage from "./pages/LandingPage";
import ProtectedRoute from "./routes/ProtectedRoute";
import { Route, Routes, useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/landing-page", { replace: true });
  }, []);
  return (
    <Routes>
      {/* public routes */}
      <Route path="/landing-page" element={<LandingPage />} />

      {/* protected routes */}
      <Route element={<ProtectedRoute />}>{/* error route */}</Route>
    </Routes>
  );
}

export default App;
