import React, { useState } from 'react'
import Button from '../../../../../components/ui/Button'
import { cross } from '../../..'
import useToggle from '../../../../../hooks/useToggle'
import open from "./open.svg"

const FaqList = ({question, answer}) => {
  const [showFaq, setShowFaq] = useToggle()
  return (
    <li 
    className='faq-list-item' 
    onClick={setShowFaq}
    style={{
      height: showFaq ? "fit-content" : "7.8rem",
      backgroundColor: showFaq && "#3331",
      maxHeight: "none",
      // transition: "all, .3s linear"
    }}
    >
      <div 
        className="faq-question-container"
        style={{
          alignItems:  showFaq && "baseline"
        }}
      >
        {
          showFaq 
          ?  <p className="faq-answer">{answer}</p>
          : <h3 className="faq-question">{question}</h3>
        }
        
        <Button 
          className={showFaq ? "show-faq" : "toggle-faq-answer"} 
          text={showFaq ? 
            <svg width="clamp(2rem, 2vw, 4rem)" height="clamp(2rem, 2vw, 4rem)" viewBox="0 0 40 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="2" x2="40" y2="2" stroke="white" stroke-width="4"/>
            </svg>

          : <svg width="clamp(2rem, 2vw, 4rem)" height="clamp(2rem, 2vw, 4rem)" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="18" x2="40" y2="18" stroke="white" stroke-width="4"/>
              <line x1="20" y1="40" x2="20" stroke="white" stroke-width="4"/>
            </svg>
            } 
          handleEvent={() => setShowFaq}
        />
      </div>
      {/* <img src={cross} alt="cross"/> */}
    </li>
  )
}

export default FaqList
