import React from 'react'
import "./shared-background.css"
import shapes from "./b.svg"

const SharedBackground = () => {
  return (
    <img
        className="shared-background"
        aria-hidden="true"
        src={shapes}
     />
      
  )
}

export default SharedBackground
