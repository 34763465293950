import React, { useState } from 'react'
import "./waitlist-modal.css"
import waitlist from "./waitlist.svg"
import arrow from "./arrow.svg"
import Button from '../../../../components/ui/Button'
import { getFetch } from '../../../../libs/fetch'
import { API_URL } from '../../../../services/api'

const WaitListModal = ({setShowWaitlist, setShowMessage}) => {
  const [email, setEmail] = useState("")

  const handleWaitList = async (e) => {
    e.preventDefault()
    if(!navigator.onLine){
      setShowMessage("No network connection.")
      return;
    }

    try{
      const payload =  {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({email})
      }
      
      const info = await getFetch(`${API_URL}/waitlist/postWaitlist`, payload)
      console.log(info)
      if(info.error){
        throw new Error(info.error)
      }
      setShowMessage(info.message)
      setShowWaitlist(false)
    }
    catch(error){
      setShowMessage(error.message)
    }
  }

  return (
    <aside className="waitlist">
      <Button
        className={"close-waitlist"}
        handleEvent={() => setShowWaitlist(false)}
        type={"button"}
        text={
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 384 512">
            <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/>
          </svg>
        }
      />

      <img 
        className="waitlist"
        src={waitlist} 
        alt="Waitlist" 
      />
      
      <form className="waitlist" onSubmit={handleWaitList}>
        <p className='waitlist'>Join the waitlist and become one of the first persons to try out Ventmoir at launch</p>

        <input
          placeholder="email"
          className="waitlist"
          required
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
        />

        <Button
          className="waitlist"
          text={
            <div className="waitlist">
              <p>Submit</p>
              <svg aria-hidden="true" width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 5C0 4.84127 0.0508906 4.7123 0.152672 4.6131C0.254453 4.51389 0.386768 4.46429 0.549618 4.46429H14.0458L10.4427 0.952381C10.3206 0.833333 10.2595 0.694445 10.2595 0.535714C10.2595 0.376984 10.3206 0.248015 10.4427 0.148809C10.5649 0.0496035 10.7074 0 10.8702 0C11.0331 0 11.1756 0.0396824 11.2977 0.119047L15.8779 4.58333C15.9593 4.70238 16 4.84127 16 5C16 5.15873 15.9593 5.29762 15.8779 5.41667L11.2977 9.88095C11.1756 9.96032 11.0331 10 10.8702 10C10.7074 10 10.5649 9.9504 10.4427 9.85119C10.3206 9.75198 10.2595 9.62302 10.2595 9.46429C10.2595 9.30556 10.3206 9.16667 10.4427 9.04762L14.0458 5.53571H0.549618C0.386768 5.53571 0.254453 5.48611 0.152672 5.3869C0.0508906 5.2877 0 5.15873 0 5Z" fill="white"/>
              </svg>
            </div>
          }
        />
      </form>
    </aside>
  )
}

export default WaitListModal
