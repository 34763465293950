import React, { useEffect, useRef, useState } from 'react'
import Header from '../features/landing/components/header/Header'
import { AboutUs, ContactUs, Faqs, Footer, Hero, HowWeHelp } from '../features/landing/index'
import DynamicFormField from '../components/ui/DynamicFormField'
import { Helmet } from 'react-helmet'
import { WEBSITE_URL } from '../CONST/const'
import JoinCommunity from '../features/landing/components/joinCommunity/JoinCommunity'

const LandingPage = () => {
  const [showWaitlist, setShowWaitlist] = useState(false)
  const [showMessage, setShowMessage] = useState("")


  const howWeHelpRef = useRef()
  const aboutUsRef = useRef()
  const faqRef = useRef()
  const contactRef = useRef()

  const structuredData = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    'name': 'Ventmoir Landing Page',
    'description': 'Welcome to Ventmoir, a mental health social platform. Discover supportive mental health societies, helpful resources, and personal stories on Ventmoir..',
    'url': window.location.href,
    'mainEntity': {
        '@type': 'Organization',
        'name': 'Ventmoir',
        'url': 'https://www.ventmoir.com',
        'logo': "https://i.ibb.co/K9kYY5H/v.jpg",
        'sameAs': [
            'https://x.com/Ventmoir',
            'https://www.linkedin.com/company/vent-moir'
        ]
    }
  });

  useEffect(() => {
    if(showMessage){
      setTimeout(() => {
        setShowMessage("")
      }, 5000);
    }
  }, [showMessage])
  
  return (
    <main itemScope itemType="http://schema.org/WebPage">
      <Helmet>
        <title>Landing Page | Ventmoir</title>
        <meta name="description" content="Welcome to Ventmoir, a mental social platform. Discover supportive mental health societies, helpful resources, and personal stories on Ventmoir.." />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Ventmoir" />
        <meta property="og:description" content="Welcome to Ventmoir, a mental social platform. Discover supportive mental health societies, helpful resources, and personal stories on Ventmoir.." />
        <meta property="og:url" content={WEBSITE_URL} />
        <meta property="og:image" content="https://i.ibb.co/K9kYY5H/v.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Ventmoir" />
        <script type="application/ld+json">{structuredData}</script>
    </Helmet>
    {showMessage && <p
      style={{
        position: 'fixed',
        top: '2rem',
        left: "50%",
        transform: "translateX(-50%)",
        padding: '1rem 2rem',
        // backgroundColor: '#4caf50',
        backgroundColor: '#407bff',
        color: 'white',
        borderRadius: '.4rem',
        boxShadow: '0 .2rem .4rem rgba(0, 0, 0, 0.2)',
        zIndex: 3,
        opacity: showMessage ? 1 : 0,
        transition: 'opacity 1s ease-in-out',
        minWidth: "10rem",
        textAlign: "center",
      }}>
        {showMessage}
      </p>}
      <Header
        howWeHelpRef={howWeHelpRef}
        aboutUsRef={aboutUsRef}
        faqRef={faqRef}
        contactRef={contactRef}
        setShowWaitlist={setShowWaitlist}
      />
      <Hero showWaitlist={
        showWaitlist} 
        setShowWaitlist={setShowWaitlist}
        setShowMessage={setShowMessage}
      />
      <AboutUs ref={aboutUsRef} />
      <JoinCommunity />
      <HowWeHelp ref={howWeHelpRef}/>
      <Faqs ref={faqRef} />
      <ContactUs ref={contactRef} />
      <Footer setShowWaitlist={setShowWaitlist} showWaitlist={showWaitlist} />
    </main>
  )
}

export default LandingPage
