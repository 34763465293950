import React, { forwardRef } from 'react'
import "./about-us.css"
import AboutArticle from './components/AboutArticle'

const AboutUs = forwardRef(({prop}, ref) => {
  const aboutArticles = [
    {
      title: 'Our Mission',
      content:
        'At Ventmoir, our mission is simple yet profound: to foster mental well-being through understanding, empathy, and connection. We believe that no one should face their mental health journey alone. Our platform is dedicated to providing a haven where individuals can find support, solace, and a path to healing.',
    },
    {
      title: 'Our Story',
      content:
        'Ventmoir was born out of a deep desire to make a positive impact on the mental health landscape. Our journey began with a vision to create a safe and judgment-free space where people could freely share their thoughts and emotions. It was this vision that led to the birth of our platform.',
    },
    {
      title: 'Our Commitment to Privacy',
      content:
        'Your privacy is paramount to us. We have stringent measures in place to safeguard your data and ensure your confidentiality at every step of your journey with us.',
    },
    {
      title: 'Our Future Goals',
      content:
        'As we look ahead, we envision a future where Ventmoir continues to be a beacon of hope and healing in the mental health space. We\'re committed to enhancing our platform, forging partnerships, and expanding our support network to serve you better.',
    },
  ];

  return (
    <section className="about-section" ref={ref}>
        <div className="about-wrapper">

        
        <h2 className="about-head">About us</h2>

        <article className="about-container">

          <div className="about-img-container">
            <img 
              className="about-img"
              src="https://img.freepik.com/free-photo/front-view-interracial-friends-cheering_23-2148212340.jpg?t=st=1721825186~exp=1721828786~hmac=dbfd1a943e273f05140abba2f59ec4c7fba4301435793e1c28baea723a8c2da3&w=900" 
              alt="Happy diverse people holding hands" 
            />
          </div>

          <ul className="about-list">
            <li className="about-list-item about-list-first-item"> 
              <h3 className="about-list-item-title">Our Commitment to Mental Wellness</h3>
              <p className="about-list-item-text">
                Access to mental health care should be a fundamental right, ensuring that everyone has equitable access to support and services they need. At Ventmoir, we are committed to promoting this belief through our platform/our mission, striving to empower individuals to prioritize their mental well-being without barriers.
              </p>
            </li>

            <li className="about-list-item">
              <h3 className="about-list-item-title">Our Mission</h3>
              <p className="about-list-item-text">
                At Ventmoir, our mission is simple yet profound: to foster mental well-being through understanding, empathy, and connection. We believe that no one should face their mental health journey alone. Our platform is dedicated to providing a haven where individuals can find support, solace, and a path to healing.
              </p>
            </li>

            <li className="about-list-item">
              <h3 className="about-list-item-title">Our Story</h3>
              <p className="about-list-item-text">
                Ventmoir was born out of a deep desire to make a positive impact on the mental health landscape. Our journey began with a vision to create a safe and judgment-free space where people could freely share their thoughts and emotions. It was this vision that led to the birth of our platform.
              </p>
            </li>
            <li className="about-list-item">
              <h3 className="about-list-item-title">Our Commitment to Privacy</h3>
              <p className="about-list-item-text">
              At Ventmoir, your privacy isn't just a policy, it is a commitment. We are committed to create a trusted space where you can connect, share and find support without compromising your personal information. Our dedication to protecting your privacy ensures that your journey towards mental well-being is secure and respected.
              </p>
            </li>

          </ul>
        </article>
        </div>
    </section>
  )
})

export default AboutUs
