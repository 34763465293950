import React, { Suspense } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { getCurrentUser } from '../libs/getCurrentUser'
import Loader from '../components/loader/Loader'

const ProtectedRoute = () => {
    const location = useLocation()
    const currentUser = getCurrentUser()
    const isAuth = currentUser && Object.keys(currentUser).length > 0
    
  return (isAuth
    ? 
        <Suspense fallback={<Loader />}>
            <Outlet />
        </Suspense>
    : 
        <Navigate to="/login" state={{from: location}} replace />
  );
}

export default ProtectedRoute
