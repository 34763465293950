import React, { forwardRef } from 'react'
import "./faqs.css"
import FaqList from './components/FaqList';
import { shape, union, useWindowWidth } from '../..';
import shapes from "./shapes.svg"

const Faqs = ({}, ref) => {
  const {screenWidth} = useWindowWidth()
  const isMobile = screenWidth < 767
    const faqs = [
      // {
      //   question: "How Do I Create an Account?",
      //   answer: "Creating an account is simple! Just click on the 'Join Us' or 'Get Started' button on the homepage. Fill in the required information, such as your email address, username, and password. Once you've submitted the form, you'll receive an OTP (One-Time Password) to verify your email. Enter the OTP in the provided inputs to activate your account, and you're all set to start exploring our platform!",
      // },
      // {
      //   question: "What Are Societies, and How Do They Work?",
      //   answer: `Societies are specialized communities within our platform that focus on specific mental health topics or interests. When you join a society, you gain access to discussions, resources, and events related to that topic. To join a society, simply navigate to the "Societies" section of the website, browse the available options, and click on the "Join" button next to the society you're interested in. You can then participate in discussions, connect with like-minded individuals, and contribute to the community`,
      // },
      {
        question: "What is Ventmoir?",
        answer: `Ventmoir is a mental health social platform which provides a supportive and inclusive space where individuals can openly and anonymously vent, share their experiences, get support from societal issues affecting mental health and access valuable mental health resources.`,
      },
      {
        question: "What Are Societies, and How Do They Work?",
        answer: `Societies are specialized, supportive communities where individuals with similar interests, experiences, or challenges related to mental health can come together. Each society focuses on a particular mental health topic, such as anxiety, depression, stress management, or mindfulness. They provide a safe space for members to share their stories, offer and receive support, and access valuable resources and information.`,
      },
      // {
      //   question: "What If I Forget My Password?",
      //   answer: "If you forget your password, don't worry! You can easily reset it by clicking on the 'Forgot Password' link on the login page. Follow the instructions provided, and you'll receive an email with a link to reset your password. Make sure to choose a strong and memorable password to keep your account secure. If you encounter any issues or need further assistance, feel free to reach out to our support team, and we'll be happy to help you regain access to your account.",
      // },
      {
        question: "What Are the Platform Rules?",
        answer: `Our platform rules are designed to ensure a safe, respectful, and inclusive environment for all users. These rules govern behavior, content, and interactions within the community. They cover topics such as prohibited content, respectful communication guidelines, and consequences for violating the rules. You can find the detailed platform rules in our Society Guidelines section, where we outline expectations and standards for user conduct. By adhering to these rules, we aim to foster a positive and supportive community experience for everyone`,
      },
      {
        question: "How Can I Provide Feedback or Contact Support?",
        answer: `Your feedback is valuable to us, and we encourage you to share your thoughts, suggestions, and concerns with us. You can provide feedback or contact our support team through various channels. To send us feedback, simply navigate to the "Contact Us" section on our website's landing page, where you can fill out a form or send us an email. If you require assistance or have specific questions, our support team is available to help. You can reach out to us via email, live chat, or phone, and we'll do our best to address your inquiries promptly and effectively.`,
      },
      {
        question: "Is My Information Kept Confidential?",
        answer: `Yes, we take the privacy and confidentiality of your information seriously. We have security measures in place to safeguard your personal data and ensure that it remains confidential. Your information is encrypted and stored securely, and we adhere to strict privacy policies and regulations to protect your privacy rights. We only collect and use your information for the purposes outlined in our Privacy Policy, and we do not share your data with third parties without your consent. You can trust that your information is kept confidential and treated with the utmost care and respect`,
      },
      // {
      //   question: "Can I Change My Username?",
      //   answer: `Yes, you can change your username, but please note that username changes are limited to once per month. To change your username, navigate to your account settings or profile page, where you'll find the option to edit your username. After submitting your new username, you'll need to wait until the next month to make another change. This policy helps maintain consistency and prevents misuse of the username change feature.`,
      // },
      {
        question: "How Can I Propose a New Society?",
        answer: `If you have an idea for a new society that you'd like to propose, we'd love to hear from you! To submit a proposal for a new society, please contact our support team. Provide a detailed description of your proposed society, including its purpose, objectives, and potential benefits to the community. Our team will review your proposal and consider it for inclusion in our platform's societies. We appreciate your initiative and contribution to expanding our community's diversity and interests.`,
      },
      {
        question: "Is the Platform Accessible on Mobile Devices?",
        answer: `Yes, our platform is fully accessible on mobile devices, including smartphones and tablets. We've designed our website to be responsive and optimized for mobile browsing, ensuring a seamless and user-friendly experience across different screen sizes and devices. Whether you're using an iOS or Android device, you can access all the features and functionality of our platform from the palm of your hand. Stay connected and engaged with our community wherever you go, with the convenience of mobile accessibility.`,
      },
      // {
      //   question: "What If I Want to Become a Confidante?",
      //   answer: 'To create a FAQ, simply identify the most common questions that users may have and then write clear and concise answers to those questions.',
      // },
    ];

  return (
    <section className="faq-section" ref={ref}>
       {!isMobile && <img
        className="shared-background"
        aria-hidden="true"
        src={shapes}
        style={{zIndex: "-1"}}
      />}
      <div className="faq-wrapper">
        <div className="faq-title-container">
          <h2 className="faq-title">Frequently Asked Questions</h2>
          <small className="faq-text">Got questions? We have answers.</small>
        </div>
      </div>

      <ul className="faq-list">
        {
          faqs.map((faq, index) => {
            return <FaqList key={index} question={faq.question}  answer={faq.answer} />
          })
        }
      </ul>
    </section>
  )
}

export default forwardRef(Faqs)
