import React, { useEffect, useRef, useState } from 'react'
import "./header.css"
import { NavLink } from 'react-router-dom'
import { closeMenu, ellipse, ellipse7, hamburger, useWindowWidth} from "../../index"
import brandLogo from "../../../../assets/svgs/brandLogo.svg"

const Header = ({
  howWeHelpRef,
  aboutUsRef,
  faqRef,
  contactRef,
  setShowWaitlist
}) => {
  const [showHeaderNav, setShowHeaderNav] = useState(false)
  const {screenWidth} = useWindowWidth()

  useEffect(() => {
    if(screenWidth >= 768){
      setShowHeaderNav(true)
    }
    else{
      setShowHeaderNav(false)
    }
  }, [screenWidth])

  const isMobile = screenWidth <= 767;

  const handleHamburgerMenuClick = () => {
    setShowHeaderNav(!showHeaderNav);
  };

  const renderHeaderNav = () => {
    if (!showHeaderNav) {
      return null;
    }

    const scrollToSection = (sectionRef) => {
      sectionRef?.current?.scrollIntoView(true);
    };

    const navigationLinks = [
      {
        path: "",
        label: "How we help",
        key: 1,
        onClick: () => scrollToSection(howWeHelpRef),
      },
      {
        path: "https://blog.ventmoir.com",
        label: "Blog",
        key: 2,
      },
      {
        path: "",
        label: "About Us",
        key: 3,
        onClick: () => scrollToSection(aboutUsRef),
      },
      {
        path: "",
        label: "FAQS",
        key: 4,
        onClick: () => scrollToSection(faqRef),
      },
      {
        path: "",
        label: "Contact Us",
        key: 5,
        onClick: () => scrollToSection(contactRef),
      },
      {
        path: "",
        label: "Join waitlist",
        key: 6,
        onClick: () => setShowWaitlist(true),
      },
      {
        path: "/register",
        label: isMobile ?  "Join Us" : "Get Started",
        key: 7,
        onClick: () => scrollToSection(howWeHelpRef),
      },
    ]

    return (
      <nav className="header-nav-container">
        <ul className="header-nav-list">
          { 
            isMobile && <li className="header-nav-item">
              <img src={closeMenu} className="close-menu" alt="close-menu" onClick={handleHamburgerMenuClick} />
            </li>
          }

          {navigationLinks.slice(0, 4).map(link => (
              <li className="header-nav-item" key={link.key}>
                <span className="item-wrapper">
                  { isMobile && <img src={ellipse} alt="ellipse" className="ellipse"/>}
                  <NavLink 
                    key={link.key} 
                    to={link?.path} 
                    onClick={() => {
                      link?.onClick()
                      isMobile && setShowHeaderNav(false)
                    }}
                    target={link?.label === "Blog" && "_blank"}
                  >
                    {link.label}
                  </NavLink>
                </span>
            </li>
          ))}
        </ul>

        <ul className="header-nav-list">
          {navigationLinks.slice(4, 6).map((link, index) => (
                <li className="header-nav-item" key={link.key}>
                  <span className="item-wrapper">
                    { isMobile && <img src={index === 1 ? ellipse7 : ellipse} alt="ellipse" className="ellipse"/>}
                    <NavLink 
                      key={link?.key} 
                      to={link?.path}
                      onClick={() => {
                        link?.onClick()
                        isMobile && setShowHeaderNav(false)
                      }}
                    >
                      {link?.label}
                    </NavLink>
                  </span>
              </li>
            ))}
        </ul>
      </nav>
    )
  }

  return (
    <header className="header-container">
      <div className="header-wrapper">
        <div className="brand-hamburger-container">
          <div 
            className="brand-container"
            onClick={() => {
              window.scrollTo({top: 0})
            }}
          >
            <img src={brandLogo} className="brand-logo" alt="brand-logo"/>
          </div>
         {
            isMobile && <div className="hamburger-container" onClick={handleHamburgerMenuClick}>
              <img src={hamburger} className="hamburger-menu" alt="hamburger-menu" />
            </div>
          }
        </div>
        {renderHeaderNav()}
      </div>
    </header>
  )
}

export default Header