import React from 'react'
import "./footer.css"
import { NavLink } from 'react-router-dom'
import { X, facebook, instagram } from '../..'
import linkedin from "./components/svgs/linkedin.svg"
import SharedBackground from '../sharedBackground/SharedBackground'

const Footer = ({setShowWaitlist, showWaitlist}) => {
  return (
    <footer className="landing-page-footer" role="contentinfo">
        <SharedBackground />
        <section className="footer-wrapper">    
            <nav className="footer-nav" role="navigation" aria-label="secondary navigation">
                <ul className="footer-nav-list">
                    <h2 className="footer">Quick links</h2>
                    <li className="footer-nav-item">
                        <NavLink aria-label="Learn more about how we help people">How we help</NavLink>
                    </li>
                    <li className="footer-nav-item">
                        <NavLink aria-label="Learn more about our organization">About Us</NavLink>
                    </li>
                    <li className="footer-nav-item">
                        <NavLink aria-label="Answers to frequently asked questions">FAQS</NavLink>
                    </li>
                    <li className="footer-nav-item">
                        <NavLink aria-label="Contact us with any questions or feedback">Contact Us</NavLink>
                    </li>
                </ul>

                <ul className="footer-policy-list"  aria-label="Social links">
                    <h2 className="footer">Legal</h2>

                    <li className="footer-policy-item">
                        <NavLink   aria-label="Learn about our privacy policy">
                            Privacy Policy
                        </NavLink>
                    </li>
                    <li className="footer-policy-item">
                        <NavLink  aria-label="Learn about our terms of use">
                            Terms of use
                        </NavLink>
                    </li>
                </ul>
            </nav>

            <div className="footer-waitlist">
                <h2 className="footer-waitlist-title">Get early access</h2>
                <p className="footer-waitlist-text">Become one of the first persons to try out Ventmoir at launch</p>
                
                <div className="footer-waitlist-link-container">
                    <NavLink
                     className="footer-waitlist-link"
                     onClick={() => setShowWaitlist(true)}
                    >Join waitlist</NavLink>
                    <NavLink to="https://chat.whatsapp.com/BX7J6GVTxrhECuJOuqAxOT" target="_blank" className="footer-waitlist-link">Join our community</NavLink>
                </div>
            </div>
        </section>

        <hr className="footer-line-break" />

        <div className="copyright-container">
            <p className="copyright-text">Copyright 2024, Ventmoir. All rights reserved</p>
            
            <ul className="footer-social-links">
                <li className="footer-social-link-item">
                    <NavLink to="https://www.linkedin.com/company/vent-moir/" target="_blank">
                        <img src={linkedin} alt="X (formely know as twitter) link" />
                    </NavLink>
                </li>
                <li className="footer-social-link-item">
                    <NavLink to="https://x.com/ventmoir" target="_blank">
                        <img src={X} alt="X (formely know as twitter) link" />
                    </NavLink>
                </li>
            </ul>
        </div>
    </footer>
  )
}

export default Footer
